<template>
  <span>
    <b-button @click="onUnloadVehicleClick" class="mr-2 mb-2">{{ $t("unload-vehicle-modal.unload-btn") }}</b-button>
    <b-modal
      id="unload-vehicle-modal"
      :title="$t('unload-vehicle-modal.title')"
      :ok-title="$t('unload-vehicle-modal.ok-btn')"
      :cancel-title="$t('unload-vehicle-modal.cancel-btn')"
      @ok.prevent="unloadVehicle"
    >
      <template v-slot:default>
        {{ $t("unload-vehicle-modal.base-msg") }}
        <div v-if="acceptedBy != null">
          {{ $t("unload-vehicle-modal.accepted-vehicle-msg", acceptedBy) }}
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
import api from "@/services/api.js";

export default {
  props: {
    vehicleId: { type: Number, required: true }
  },
  data() {
    return {
      acceptedBy: null
    };
  },
  methods: {
    onUnloadVehicleClick() {
      api.getVehicleCurrentAcceptedLoad(this.vehicleId).then(resp => {
        if (resp.data) {
          this.acceptedBy = resp.data.acceptedBy;
        }
        this.$bvModal.show("unload-vehicle-modal");
      });
    },
    unloadVehicle() {
      api
        .unloadVehicle(this.vehicleId)
        .then(() => {
          this.$bvModal.hide("unload-vehicle-modal");
          this.$router.go(-1);
        })
        .catch(err => {
          if (err.data && err.data.errorCode) {
            alert(this.$t("unload-vehicle-modal.error." + err.data.errorCode));
          } else {
            alert("unload-vehicle-modal.error.general-error");
          }
        });
    }
  }
};
</script>
