<style>
.gray-container {
  background-color: #eceeef;
  border-radius: 5px;
}
</style>

<template>
  <b-container fluid class="overflowable">
    <b-row>
      <b-col class="mb-2">
        <b-button @click="$router.push({ name: 'vehicle-management' })" class="mr-2 mb-2">
          {{ $t("current-load.to-vehicle-list-btn-caption") }}
        </b-button>
        <unload-vehicle :vehicleId="vehicleId"></unload-vehicle>
      </b-col>
      <b-col class="mb-2 gray-container" md="4" cols="12">
        <current-accepted-info :vehicleId="vehicleId"></current-accepted-info>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <load-items-list></load-items-list>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LoadItemsList from "@/components/current-load/LoadItemsList.vue";
import CurrentAcceptedInfo from "@/components/vehicle/CurrentAcceptedInfo";
import UnloadVehicle from "@/components/vehicle/UnloadVehicle.vue";

export default {
  components: {
    LoadItemsList,
    CurrentAcceptedInfo,
    UnloadVehicle
  },
  computed: {
    vehicleId() {
      return Number(this.$route.params.vehicleId);
    }
  },
  mounted() {
    this.$store
      .dispatch("currentLoad/loadData", this.vehicleId)
      .catch(() => alert(this.$t("current-load.error.while-retrieving-initial-data")));
  }
};
</script>
