<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-form-input
          v-model="filters.title"
          type="search"
          :placeholder="$t('current-load.search-placeholder')"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-container fluid class="gray-container">
      <b-row class="mb-2 p-3">
        <b-col>
          <b-form-checkbox v-model="showLessThanMinimal" switch>
            {{ $t("current-load.less-than-minimal-switch") }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="showLessThanRecommended" switch>
            {{ $t("current-load.less-than-recommended-switch") }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="showMoreThanRecommended" switch>
            {{ $t("current-load.more-than-recommended-switch") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
    <b-overlay :show="isLoading">
      <b-table
        :fields="fields"
        :items="filteredItems"
        :empty-text="$t('current-load.table.empty-text')"
        show-empty
        responsive
      >
        <template v-slot:cell(goodsInfo)="row">{{ row.item.goodsInfo.title[$i18n.locale] }}</template>
        <template v-slot:cell(serialNumbers)="row">
          <serial-numbers
            v-if="row.item.goodsInfo.hasSerialNumber"
            @input="onSerialNumbersChanged(row.item.goodsInfo.id, $event)"
            :value="row.item.serialNumbers"
            :placeholder="$t('current-load.table.serial-numbers-placeholder')"
            :state="row.item.serialNumbers.length == row.item.balance"
            :availableSerialNumbers="availableSerialNumbersForProduct(row.item.goodsInfo.id)"
          ></serial-numbers>
        </template>
        <template v-slot:cell(expended)="row">
          <b-input
            type="number"
            :value="row.item.expended"
            @input="onExpendedInput(row.item.goodsInfo.id, $event)"
          ></b-input>
        </template>
        <template v-slot:cell(balance)="row">
          <b-input
            type="number"
            :value="row.item.balance"
            :state="row.item.balance >= 0"
            @input="onBalanceInput(row.item.goodsInfo.id, $event)"
          ></b-input>
        </template>
      </b-table>
    </b-overlay>
    <b-row>
      <b-col>
        <b-button variant="danger" @click="onResetChangesClick" class="mr-2 mb-2">
          {{ $t("current-load.reset-changes-btn-caption") }}
        </b-button>
        <b-button @click="onApplyChangesClick" :disabled="!isValid" class="mb-2">
          {{ $t("current-load.apply-changes-btn-caption") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SerialNumbers from "@/components/shared/SerialNumbers.vue";
import comparator from "@/services/string-comparator.js";
import api from "@/services/api.js";
import { ADDITIONAL_LOAD } from "@/const/vehicle-load-types.js";

export default {
  components: {
    SerialNumbers
  },
  data() {
    return {
      filters: {
        title: ""
      },
      showLessThanMinimal: false,
      showLessThanRecommended: false,
      showMoreThanRecommended: false
    };
  },
  computed: {
    filteredItems() {
      let items = this.$store.state.currentLoad.loadItems;
      let filteredByTitle = items.filter(x =>
        comparator.compareRows(x.goodsInfo.title[this.$i18n.locale], this.filters.title)
      );
      let filteredByCount = filteredByTitle.filter(x => {
        // if none of filter is swithed on - no need to check
        if (!this.showLessThanMinimal && !this.showLessThanRecommended && !this.showMoreThanRecommended) return true;

        if (this.showLessThanMinimal && x.count < x.minQuantity) {
          return true;
        }
        if (!this.showLessThanMinimal && this.showLessThanRecommended && x.count < x.quantity) {
          return true;
        }
        if (this.showMoreThanRecommended && x.count > x.quantity) {
          return true;
        }
        return false;
      });
      return filteredByCount;
    },
    isValid() {
      return this.$store.getters["currentLoad/isValid"];
    },
    isLoading() {
      return this.$store.state.currentLoad.isLoading;
    },
    fields() {
      return [
        { key: "goodsInfo", label: this.$t("current-load.table.title"), sortable: true },
        { key: "minQuantity", label: this.$t("current-load.table.min-quantity"), sortable: true },
        { key: "quantity", label: this.$t("current-load.table.recommended-quantity"), sortable: true },
        { key: "serialNumbers", label: this.$t("current-load.table.serial-numbers") },
        { key: "count", label: this.$t("current-load.table.count"), sortable: true },
        { key: "expended", label: this.$t("current-load.table.expended"), sortable: true },
        { key: "balance", label: this.$t("current-load.table.balance"), sortable: true }
      ];
    }
  },
  methods: {
    availableSerialNumbersForProduct(goodsInfoId) {
      let availableSnList = this.$store.state.currentLoad.goodsSerialNumbers.find(x => x.goodsInfoId == goodsInfoId);
      return availableSnList ? availableSnList.serialNumbers : [];
    },
    onSerialNumbersChanged(id, evt) {
      this.$store.commit("currentLoad/setNewSerialNumbers", { id, newSerialNumbers: evt });
    },
    onBalanceInput(id, evt) {
      this.$store.commit("currentLoad/setBalance", { id, newBalance: Number(evt) });
    },
    onExpendedInput(id, evt) {
      this.$store.commit("currentLoad/setExpended", { id, newExpended: Number(evt) });
    },
    onResetChangesClick() {
      this.$store.commit("currentLoad/reset");
    },
    onApplyChangesClick() {
      if (this.isValid) {
        let vm = {
          vehicleId: this.$store.state.currentLoad.vehicleId,
          type: ADDITIONAL_LOAD,
          loadItems: this.$store.getters["currentLoad/itemsForExport"]
        };
        api
          .setVehicleLoad(vm)
          .then(() => this.$router.push({ name: "vehicle-management" }))
          .catch(() => alert(this.$t("current-load.error.while-applying-changes")));
      } else {
        alert(this.$t("current-load.error.validation-failed"));
      }
    }
  }
};
</script>
