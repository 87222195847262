<style scoped>
.is-valid {
  border: solid 1px #28a745 !important;
  border-radius: 0.25rem;
}
.is-invalid {
  border: solid 1px #dc3545 !important;
  border-radius: 0.25rem;
}
.vue-tags-input :only-child {
  border: 0 !important;
}
</style>

<template>
  <vue-tags-input
    v-model="tag"
    :tags="tags"
    :autocomplete-items="autocompleteItems"
    @tags-changed="onSerialNumbersUpdated"
    :placeholder="placeholderInternal"
    :add-on-blur="false"
    :class="{ 'is-valid': isValid, 'is-invalid': isInvalid }"
    :disabled="disabled"
  />
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    VueTagsInput
  },
  props: {
    // serialNumbers and availableSerialNumbers are arrays of strings
    value: { type: Array, required: true },
    availableSerialNumbers: { type: Array, required: false },
    placeholder: { type: String, required: false },
    state: { type: Boolean, required: false, default: null },
    disabled: { type: Boolean, required: false }
  },
  data() {
    return {
      tag: ""
    };
  },
  computed: {
    tags() {
      return this.value.map(x => {
        return { text: x };
      });
    },
    placeholderInternal() {
      return this.placeholder || "";
    },
    isValid() {
      if (this.state == null) return false;
      return this.state;
    },
    isInvalid() {
      if (this.state == null) return false;
      return !this.state;
    },
    autocompleteItems() {
      if (this.availableSerialNumbers) {
        return this.availableSerialNumbers
          .filter(x => x.indexOf(this.tag) !== -1)
          .map(x => {
            return { text: x };
          });
      }
      return [];
    }
  },
  methods: {
    onSerialNumbersUpdated(newValue) {
      let serialNumbers = newValue.map(x => x.text);
      this.$emit("input", serialNumbers);
    }
  }
};
</script>

<style scoped>
.is-valid {
  border: 1px solid #62e92c;
}
.is-invalid {
  border: 1px solid #d62525;
}
</style>
