<template>
  <div v-if="acceptedAt" class="text">
    <b-col> {{ $t("current-accepted-info.vehicle-number") + ": " + vehicleNumber }} </b-col>
    <b-col> {{ $t("current-accepted-info.accepted-by") + ": " + acceptedBy.userName }} </b-col>
    <b-col> {{ $t("current-accepted-info.accepted-at") + ": " + $d(Date.parse(acceptedAt), "log") }} </b-col>
  </div>
  <div v-else>
    <h3>{{ $t("current-accepted-info.not-accepted") }}</h3>
  </div>
</template>

<script>
import api from "@/services/api.js";

export default {
  props: {
    vehicleId: { type: Number, required: true }
  },
  data() {
    return {
      acceptedAt: null,
      acceptedBy: {},
      vehicleNumber: ""
    };
  },
  mounted() {
    api
      .getVehicleCurrentAcceptedLoad(this.vehicleId)
      .then(resp => {
        if (resp.data) {
          this.acceptedAt = resp.data.acceptedAt;
          this.acceptedBy = resp.data.acceptedBy;
          this.vehicleNumber = resp.data.vehicleNumber;
        }
      })
      .catch(() => alert(this.$t("current-accepted-info.error.general")));
  }
};
</script>
